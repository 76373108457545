import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { LoadSuspensionTypesAsOptions } from "src/app/shared/states/prefill-data/prefill-data.actions";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults, SetError } from "../../admin.state";
import { SuspensionTypesService } from "../suspension-types.service";
import { AddSuspensionType, LoadSuspensionTypes, RemoveSuspensionType, UpdateSuspensionType } from "./suspension-types.actions";
import { SuspensionTypesStateModel } from "./suspension-types.model";

@Injectable()
@State<SuspensionTypesStateModel>({
  name: "suspensionTypes",
  defaults: AdminPagesStateDefaults
})
export class SuspensionTypesState {
  constructor(
    private suspensionTypesService: SuspensionTypesService
  ) {}

  @Selector() static suspensionTypes(state: SuspensionTypesStateModel) { return state.items; }
  @Selector() static loading(state: SuspensionTypesStateModel) { return state.loading; }
  @Selector() static saving(state: SuspensionTypesStateModel) { return state.saving; }

  @Action(LoadSuspensionTypes)
  load(ctx: StateContext<SuspensionTypesStateModel>) {
    ctx.patchState({
      items: [],
      loading: true
    });

    return this.suspensionTypesService.getSuspensionTypes().pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            loaded: true,
            loading: false
          });
          ctx.dispatch(new LoadSuspensionTypesAsOptions(response.items));
        },
        (error) => {
          ctx.patchState({ items: [], loading: false });
          ctx.dispatch(new SetError({ loading: error }));
        }
      )
    );
  }

  @Action(AddSuspensionType)
  addSuspensionType(ctx: StateContext<SuspensionTypesStateModel>, action: AddSuspensionType) {
    ctx.patchState({ saving: true });
    
    return this.suspensionTypesService.createSuspensionType(action.payload).then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: [response, ...state.items],
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(UpdateSuspensionType)
  updateSuspensionType(ctx: StateContext<SuspensionTypesStateModel>, action: UpdateSuspensionType) {
    ctx.patchState({ saving: true });
    return this.suspensionTypesService.updateSuspensionType(action.payload).then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: getUpdatedItems(response, state.items),
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(RemoveSuspensionType)
  removeSuspensionType(ctx: StateContext<SuspensionTypesStateModel>, action: RemoveSuspensionType) {
    return this.suspensionTypesService.removeSuspensionType(action.suspensionTypeId).then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        const updated = state.items.filter(item => {
          return item.id !== action.suspensionTypeId;
        });
        ctx.patchState({ items: updated });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ loading: false });
      }
    );
  }
}
