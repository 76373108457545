import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SuspensionType } from "src/app/core/interfaces/suspension-type.interface";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";

@Injectable({ providedIn: "root" })
export class SuspensionTypesService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = `${this.env.getValueTransferState(API_URL)}/app/suspensionType`;
  }

  getSuspensionTypes() {
    return this.http.get<any>(this.apiUrl);
  }

  updateSuspensionType(suspensionType: SuspensionType) {
    return this.http.put<any>(`${this.apiUrl}/${suspensionType.id}`, suspensionType).toPromise();
  }

  createSuspensionType(suspensionType: SuspensionType) {
    return this.http.post<any>(`${this.apiUrl}`, suspensionType).toPromise();
  }

  removeSuspensionType(suspensionTypeId: string) {    
    return this.http.delete(`${this.apiUrl}/${suspensionTypeId}`).toPromise();
  }
}
